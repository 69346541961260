import React from 'react'
import { styled } from '@mui/material/styles'
import md5 from 'md5'
import { Avatar, Grid } from '@mui/material'

const PREFIX = 'Gravatar'
const classes = {
  lg: `${PREFIX}-lg`,
  md: `${PREFIX}-md`,
  sm: `${PREFIX}-sm`
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.lg}`]: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  [`& .${classes.md}`]: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  [`& .${classes.sm}`]: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  }
}))

const rutaAlmacenamiento = process.env.REACT_APP_STORAGE

function Gravatar(props) {
  const id = props.id  || 'SinIdAvatar'
  const icono = props.icono || ''
  const nombre = props.nombre || 'Avatar del módulo'
  const tamanio = props.tamanio || 'lg'
  const hash = md5(id)

  return <StyledGrid><Avatar alt={nombre} src={icono ? `${rutaAlmacenamiento}/images/cards/${icono}` : `https://www.gravatar.com/avatar/${hash}?d=identicon`} className={classes[tamanio]} /></StyledGrid>
}

export default Gravatar