async function LogOut(uid, token) {
  document.cookie = `_siguid=; path=/; domain=${process.env.REACT_APP_DOMINIO}; expires=${new Date(0).toUTCString()}`
  await fetch(`${process.env.REACT_APP_LOGIN_API}/logout/${uid}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorization: token,
    }
  })
  .catch(err => {
    console.error(err)
  })
  window.location.href = '/'
}

export default LogOut