import React from 'react'
import { styled } from '@mui/material/styles'
import Cookies from 'universal-cookie'
import { FormControl, FormGroup, FormControlLabel, Switch, Button, Grid, Box, Typography } from '@mui/material'

const PREFIX = 'Accesibility'
const classes = {
  controles: `${PREFIX}-controles`,
  tamanioletra: `${PREFIX}-tamanioletra`
}

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.controles}`]: {
    marginBottom: '30px',
    minWidth: '200px'
  },
  [`& .${classes.tamanioletra}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
}))

const cookies = new Cookies()

class AccesibilityHC extends React.Component {
  state = {
    altoContraste: (cookies.get('AltoContraste')) ? true : false,
    invertirColores: (cookies.get('InvertirColores')) ? true : false,
    aumentoLetra: (cookies.get('AumentoLetra')) ? true : false,
  }

  onSetCookieAC = e => {
    if (e.target.checked) {
      cookies.set('AltoContraste', 'UTN', { path: '/', domain: process.env.REACT_APP_DOMINIO, maxAge: Math.pow(9, 10) })
      this.setState({ altoContraste: true })
    }
    else {
      cookies.remove('AltoContraste', { path: '/', domain:process.env.REACT_APP_DOMINIO })
      this.setState({ altoContraste: false })
    }
  }

  onSetCookieIC = e => {
    if (e.target.checked) {
      cookies.set('InvertirColores', 'UTN', { path: '/',  domain: process.env.REACT_APP_DOMINIO, maxAge: Math.pow(9, 10) })
      this.setState({ invertirColores: true })
    }
    else {
      cookies.remove('InvertirColores', { path: '/', domain:process.env.REACT_APP_DOMINIO })
      this.setState({ invertirColores: false })
    }
  }

  onSetCookieAL = e => {
    if (e.target.checked) {
      cookies.set('AumentoLetra', 'UTN', { path: '/', domain: process.env.REACT_APP_DOMINIO, maxAge: Math.pow(9, 10) })
      this.setState({ aumentoLetra: true })
    }
    else {
      cookies.remove('AumentoLetra', { path: '/', domain:process.env.REACT_APP_DOMINIO })
      this.setState({ aumentoLetra: false })
    }
  }

  render() {
    const t = global.t || {}

    return (
      <StyledGrid>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel className={classes.controles}
              control={<Switch checked={this.state.altoContraste} onChange={this.onSetCookieAC} id="altoContraste" name="altoContraste" color="primary" />}
              label={t.T_ACTIVAR_ALTO_CONTRASTE || 'T_ACTIVAR_ALTO_CONTRASTE'}
            />
            <FormControlLabel className={classes.controles}
              control={<Switch checked={this.state.invertirColores} onChange={this.onSetCookieIC} id="invertirColores" name="invertirColores" color="primary" />}
              label={t.T_ACTIVAR_INVERTIR_COLORES || 'T_ACTIVAR_INVERTIR_COLORES'}
            />
            <Grid container direction="row" alignItems="center">
              <FormControlLabel className={classes.controles}
                control={
                <Box className={classes.tamanioletra}>
                  <Typography variant="caption">A</Typography>
                  <Switch checked={this.state.aumentoLetra} onChange={this.onSetCookieAL} id="aumentoLetra" name="aumentoLetra" color="primary" />
                  <Typography variant="h4">A</Typography>
                </Box>}
                labelPlacement="top"
                label={t.T_AMPLIAR_TAMANIO_LETRA || 'T_AMPLIAR_TAMANIO_LETRA'}
              />
            </Grid>
            <Button variant='contained' color='primary' onClick={this.props.onSubmit}>{t.T_APLICAR || 'T_APLICAR'}</Button>
          </FormGroup>
        </FormControl>
      </StyledGrid>
    )
  }
}

export default (AccesibilityHC)