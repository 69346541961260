import React from 'react'

import { AuthToken } from '../middlewares/MiddleWare'
import Box from '@mui/material/Box'
import Portal from '../pages/Portal'
import NotFound from '../pages/NotFound'
import Footer from './Footer'

function Layout(props) {
  const despliegaManual = /Help|Privacy|Term/.test(window.location.pathname.split('/')[1] || '')

  return (
    <React.Fragment>
      <AuthToken />
      {despliegaManual &&
        <NotFound />
      }
      {!despliegaManual &&
        <>
          <Box id="portal">
            <Portal ruta={props.ruta} children={props.children} />
          </Box>
          <Footer />
        </>
      }
    </React.Fragment>
  )
}

export default Layout