import React, {useState, useMemo} from 'react'
import { styled } from '@mui/material/styles'
import jwt from 'jsonwebtoken'
import clsx from 'clsx'
import { Typography, Link, Button, IconButton, TextField, InputAdornment } from '@mui/material'
import { Paper, Box, Grid, Card, CardHeader, CardMedia, CardContent, CardActions, Collapse, Divider } from '@mui/material'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { Alert } from '@mui/material'
import { Search, Favorite, ExpandMore } from '@mui/icons-material'
import Cookies from 'universal-cookie'
import moment from 'moment'
import 'moment/locale/es'
import { indigo } from '@mui/material/colors'

import Gravatar from './Gravatar'
import LogOut from '../components/LogOut'

const PREFIX = 'ErpList'
const classes = {
  sinEspaciado: `${PREFIX}-sinEspaciado`,
  espaciadoCards: `${PREFIX}-espaciadoCards`,
  espaciadoCardAction: `${PREFIX}-espaciadoCardAction`,
  espaciadoSuperior: `${PREFIX}-espaciadoSuperior`,
  buscar: `${PREFIX}-buscar`,
  modulos: `${PREFIX}-modulos`,
  menu: `${PREFIX}-menu`,
  nombreModulo: `${PREFIX}-nombreModulo`,
  iconoModulo: `${PREFIX}-iconoModulo`,
  infoModulo: `${PREFIX}-infoModulo`,
  contenidoMenu: `${PREFIX}-contenidoMenu`,
  enlacesMenu: `${PREFIX}-enlacesMenu`,
  titulo: `${PREFIX}-titulo`,
  textoDescripcion: `${PREFIX}-textoDescripcion`,
  textoConImagen: `${PREFIX}-textoConImagen`,
  expandido: `${PREFIX}-expandido`,
  expandidoAbierto: `${PREFIX}-expandidoAbierto`
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.sinEspaciado}`]: {
    margin: 0,
    padding: 0,
  },
  [`& .${classes.espaciadoCards}`]: {
    margin: theme.spacing(1),
  },
  [`& .${classes.espaciadoCardAction}`]: {
    padding: 2,
  },
  [`& .${classes.espaciadoSuperior}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${classes.buscar}`]: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 800,
    },
  },
  [`& .${classes.modulos}`]: {
    background: 'transparent',
    width: 250,
    minHeight: 145,
    textTransform: 'initial',
    padding: '0 8px 50px 8px',
  },
  [`& .${classes.menu}`]: {
    width: 315,
    minHeight: 80,
    padding: 0,
    border: '1px solid #707070',
  },
  [`& .${classes.nombreModulo}`]: {
    '&:last-child': {
      paddingBottom: 0,
    },
    background: theme.palette.common.white,
    position: 'absolute',
    padding: 0,
    width: '100%',
    height: 50,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`& .${classes.iconoModulo}`]: {
    paddingBottom: 15,
  },
  [`& .${classes.infoModulo}`]: {
    background: '#FFF',
    minWidth: 230,
    borderRadius: '4px 4px 0 0',
    borderBottom: '1px dotted',
  },
  [`& .${classes.contenidoMenu}`]: {
    '&:last-child': {
      paddingBottom: 0,
    },
    height: 80,
    padding: '0 4px 0 4px',
  },
  [`& .${classes.enlacesMenu}`]: {
    '&:last-child': {
      paddingBottom: 0,
    },
    padding: '0 4px 20px 15px',
  },
  [`& .${classes.titulo}`]: {
    color: indigo[900],
    fontWeight: 'bold',
    display: 'inline',
  },
  [`& .${classes.textoDescripcion}`]: {
    align:'left',
    color: 'black',
    fontSize: 12,
    fontWeight: 'lighter',
    lineHeight: 'normal',
  },
  [`& .${classes.textoConImagen}`]: {
    width: 222,
  },
  [`& .${classes.expandido}`]: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  [`& .${classes.expandidoAbierto}`]: {
    transform: 'rotate(180deg)',
  }
}))

const cookies = new Cookies()
const registrado = cookies.get('_siguid') ? cookies.get('_siguid').split(' ')[1] : null
try {
  jwt.decode(registrado)
} catch (error) {
  LogOut("-1", cookies.get('_siguid').split(' ')[1])
}
const datosUsuario = jwt.decode(registrado)

class ErpListItem extends React.Component {
  state = {
    expandido: false,
    manejaIntervalo: null,
    mensajeErrorAPI: '',
    mensajeAPI: '',
  }

  componentWillUnmount() {
    clearInterval(this.manejaIntervalo)
  }

  limpiaMensajes = async () => {
    this.manejaIntervalo = setInterval(() => {
      this.setState({ mensajeErrorAPI: '', mensajeAPI: '' })
    }, 5000)
  }

  manejaExpandirClick = () => this.setState({ expandido: !this.state.expandido })

  manejaFavoritoClick = async () => {
    const t = global.t || {}
    try {
      const response = await fetch(`${process.env.REACT_APP_PORTAL_API}/favorite`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({'uid': datosUsuario.uid, 'modulo': this.props.modulo.NombreCanonico, 'estado': this.props.modulo.Favorito})
      })
      if (response.status === 200) {
        if (!this.props.modulo.Favorito) {
          this.setState({ mensajeAPI: (t.T_MENSAJE_ANIADIDO_FAVORITOS || 'T_MENSAJE_ANIADIDO_FAVORITOS') })
        }
        else {
          this.setState({ mensajeAPI: (t.T_MENSAJE_ELIMINADO_FAVORITOS || 'T_MENSAJE_ELIMINADO_FAVORITOS') })
        }
      }
      else {
        this.setState({ mensajeErrorAPI: response.status })
      }
    }
    catch (error) {
      this.setState({ mensajeErrorAPI: (t.T_ERROR_500 || 'T_ERROR_500') })
    }
    await this.limpiaMensajes()
  }

  renderCard() {
    const t = global.t || {}
    const moduloTipo = this.props.modulo.PermisosNombre !== 'Público' || datosUsuario ? 'modulos' : 'publico'

    switch(this.props.tipoCard){
      case 'Módulo':
      case 'Enlace':
        return (
          <Box flexWrap="wrap">
            <Button className={classes.modulos} href={!this.props.modulo.Enlace ? `/${moduloTipo}/${this.props.modulo.MenuId}` : `/enlace/${this.props.modulo.MenuId}/${btoa(this.props.modulo.Enlace.split('://')[1])}`}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <CardMedia className={classes.iconoModulo}>
                  <Gravatar id={this.props.modulo.NombreCanonico} icono={this.props.modulo.Icono} nombre={this.props.modulo.Nombre} />
                </CardMedia>
                <CardContent className={classes.nombreModulo}>
                  <Typography variant="body1" align="center">{this.props.modulo.Nombre}</Typography>
                </CardContent>
                <Collapse in={this.state.expandido} timeout="auto" unmountOnExit>
                  <Divider />
                  <CardContent className={classes.infoModulo}>
                    <Typography variant="body2" color="textSecondary" component="p"><Box component="span" className={classes.titulo}>{t.T_DUENIO || 'T_DUENIO'}:</Box> {this.props.modulo.Duenio}</Typography>
                    <Typography variant="body2" color="textSecondary" component="p"><Box component="span" className={classes.titulo}>{t.T_ADMINISTRADOR || 'T_ADMINISTRADOR'}:</Box> {this.props.modulo.Administrador}</Typography>
                    <Typography variant="body2" color="textSecondary" component="p"><Box component="span" className={classes.titulo}>{t.T_ENCARGADO || 'T_ENCARGADO'}:</Box> {this.props.modulo.Encargado}</Typography>
                    <Typography variant="body2" color="textSecondary" component="p"><Box component="span" className={classes.titulo}>{t.T_VERSION || 'T_VERSION'}:</Box> {this.props.modulo.Version}</Typography>
                    <Typography variant="body2" color="textSecondary" component="p"><Box component="span" className={classes.titulo}>{t.T_PUBLICADO || 'T_PUBLICADO'}:</Box> {moment(this.props.modulo.FechaPublicacion.substring(0, 10)).format("L")}</Typography>
                    <Typography variant="body2" color="textSecondary" component="p"><Box component="span" className={classes.titulo}>{t.T_ANS || 'T_ANS'}:</Box> {this.props.modulo.ANS}</Typography>
                    <Typography variant="body2" color="textSecondary" component="p"><Box component="span" className={classes.titulo}>{t.T_DUENIO || 'T_DUENIO'}:</Box> {this.props.modulo.DiccionarioDatos}</Typography>
                    <Typography variant="body2" color="textSecondary" component="p"><Box component="span" className={classes.titulo}>{t.T_DICCIONARIO || 'T_DICCIONARIO'}:</Box> {this.props.modulo.Repositorio}</Typography>
                  </CardContent>
                </Collapse>
              </Grid>
            </Button>
          </Box>
        )
      case 'Menú':
        return (
          <Box flexWrap="wrap">
            <Paper className={[classes.modulos, classes.menu].join(' ')}>
              <Link underline="none" href={this.props.modulo.MenuOpt.length > 0 || !this.props.modulo.Enlace ? null : this.props.modulo.Enlace}>
                <CardHeader className={classes.contenidoMenu} onClick={this.props.modulo.MenuOpt.length > 0 ? this.manejaExpandirClick : null}
                  action={this.props.modulo.MenuOpt.length > 0 &&
                    <IconButton style={{marginTop: 45}}
                      className={clsx(classes.expandido, { [classes.expandidoAbierto]: this.state.expandido, })} aria-expanded={this.state.expandido} aria-label={t.T_DESPLEGAR_OPCIONES_MENU || 'T_DESPLEGAR_OPCIONES_MENU'} size="large">
                      <ExpandMore />
                    </IconButton>
                  }
                  avatar={<Gravatar id={this.props.modulo.NombreCanonico} icono={this.props.modulo.Icono} nombre={this.props.modulo.Nombre} tamanio={"sm"} />}
                  title={<Typography variant="subtitle1"><Box component="span" className={classes.titulo}>{this.props.modulo.Nombre}</Box></Typography>}
                  subheader={<Box className={classes.textoDescripcion} overflow="hidden" maxHeight={44}>{this.props.modulo.Descripcion}</Box>}
                />
              </Link>
              <Collapse in={this.state.expandido} timeout="auto" unmountOnExit>
                <Divider />
                <CardContent>
                  {this.props.modulo.MenuOpt.map(function (datosMenu) {
                    return(
                      <Box key={datosMenu.MenuNombre} className={classes.enlacesMenu}>
                        <Link underline="none" href={datosMenu.Enlace}>
                          <Box component="span" className={classes.titulo} fontSize={14}>{datosMenu.MenuNombre}</Box>
                          <Grid container direction="row">
                            {datosMenu.Icono &&
                              <Gravatar id={datosMenu.MenuId} icono={datosMenu.Icono} nombre={datosMenu.MenuNombre} tamanio={"sm"} />
                            }
                            <Box className={clsx(classes.textoDescripcion, { [classes.textoConImagen] : datosMenu.Icono, })} paddingLeft={1}>{datosMenu.Descripcion}</Box>
                          </Grid>
                        </Link>
                      </Box>
                    )
                  })}
                </CardContent>
              </Collapse>
            </Paper>
          </Box>
        )
      default:
        return <Typography>{t.T_MENSAJE_PARAMETRO_INCORRECTO || 'T_MENSAJE_PARAMETRO_INCORRECTO'}</Typography>
    }
  }

  render() {
    const t = global.t || {}
    const moduleColor = cookies.get('AltoContraste') || this.props.tipoCard === 'Menú' ? '#fff' : this.props.modulo.Color

    return(
      <Card className={classes.espaciadoCards} style={{background: moduleColor}}>
        <Box>
          <Collapse in={this.state.mensajeErrorAPI !== '' ? true : false}>
            <Alert elevation={6} severity="error">{this.state.mensajeErrorAPI}</Alert>
          </Collapse>
          <Collapse in={this.state.mensajeAPI !== '' ? true : false}>
            <Alert elevation={6} severity="success">{this.state.mensajeAPI}</Alert>
          </Collapse>
        </Box>
        <CardActions disableSpacing className={clsx([], { [classes.sinEspaciado]: this.props.tipoCard === 'Menú', [classes.espaciadoCardAction]: this.props.tipoCard === 'Módulo' || this.props.tipoCard === 'Enlace'})}>
          {(registrado && this.props.modulo.PermiteFavorito === 1) && (
            <IconButton onClick={this.manejaFavoritoClick} aria-label={t.T_ANIADIR_FAVORITOS || 'T_ANIADIR_FAVORITOS'} size="large">
              <Favorite color={this.props.modulo.Favorito === 1 ? "primary" : "action"}/>
            </IconButton>
          )}
          { (this.props.tipoCard === 'Módulo' || this.props.tipoCard === 'Enlace') &&
            <IconButton
              className={clsx(classes.expandido, { [classes.expandidoAbierto]: this.state.expandido, })} onClick={this.manejaExpandirClick} aria-expanded={this.state.expandido} aria-label={t.T_MOSTRAR_INFO_MODULO || 'T_MOSTRAR_INFO_MODULO'} size="large">
              <ExpandMore />
            </IconButton>
          }
        </CardActions>
        {this.renderCard(classes)}
      </Card>
    )
  }
}

function UsaBuscarModulo(modulos, idModulo) {
  const [query, setQuery] = useState('')
  const [modulosFiltrados, cargaModulosFiltrados] = useState(modulos)

  useMemo(() => {
    if (idModulo === 'privado') {
      const result = modulos.filter(modulo => {
        return `${modulo.Nombre}`
          .toLowerCase()
          .includes(query.toLowerCase())
      })
      cargaModulosFiltrados(result)
    }
    else {
      const result = modulos.filter(modulo => {
        return `${modulo.PermisosNombre}`
          .includes('Público')
      })
      cargaModulosFiltrados(result)
    }
  }, [idModulo, modulos, query])
  return { query, setQuery, modulosFiltrados }
}

function ErpList(props) {
  const t = global.t || {}
  const modulos = props.modulos[0]
  const favoritos = props.modulos[1]
  const tipoCard = props.modulos[0][0]?.TipoMenu || 'Módulo'
  const idModulo = props.idModulo === 'Home' ? 'publico' : 'privado'
  const tamaniosAcordion = props.idModulo === 'Home' ? "100%" : { xs: "100%", sm: "100%", lg: "1100px", xl: "1400px" }
  let bandera = false

  const { query, setQuery, modulosFiltrados } = UsaBuscarModulo(modulos, idModulo)
  const [expandido, cargaExpandido] = useState('panel1a')

  const manejaTipoModulo = (panel) => (event, estaExpandido) => {
    cargaExpandido(estaExpandido ? panel : false)
  }

  const renderDetalleAcordeon = (parametro, tipoCard, modulosFiltrados) => {
    return(
      <AccordionDetails>
        <Grid container direction="row" justifyContent={tipoCard === "Menú" ? "flex-start" : "center"} alignItems="flex-start">
          {modulosFiltrados.map(modulo => {
            if (modulo.Tipo === parametro) {
              return (
                <ErpListItem modulo={modulo} classes={classes} tipoCard={modulo.TipoMenu} key={modulo.NombreCanonico} />
              )
            }
            else return null
          })}
        </Grid>
      </AccordionDetails>
    )
  }

  if (modulosFiltrados.length === 0) {
    return (
      <StyledBox>
        <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.espaciadoSuperior}>
          <Box width={1} justifyContent="center" display="flex">
            {/* filtrar módulos */}
            {idModulo === 'privado' && (
              <TextField id="filtrar" label={t.T_FILTRAR_MODULOS || 'T_FILTRAR_MODULOS'} type="search" variant="outlined" className={classes.buscar} value={query} onChange={e => { setQuery(e.target.value) }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Box>

          {favoritos.length > 0 &&
            <Box width={tamaniosAcordion} className={classes.espaciadoSuperior}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography variant="overline">{t.T_MODULOS_FAVORITOS || 'T_MODULOS_FAVORITOS'}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                    {favoritos.map(modulo => {
                      return (
                        <ErpListItem modulo={modulo} classes={classes} tipoCard={modulo.TipoMenu} key={modulo.NombreCanonico} />
                      )
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
          }

          <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.espaciadoSuperior}>
            <Typography variant="overline">{t.T_LISTA_MODULOS || 'T_LISTA_MODULOS'}</Typography>
            <Typography variant="h4" gutterBottom>{t.T_MODULO_NO_EXISTE || 'T_MODULO_NO_EXISTE'}</Typography>
          </Grid>
        </Grid>
      </StyledBox>
    )
  }

  return (
    <StyledBox>
      <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.espaciadoSuperior}>
        <Box width={1} justifyContent="center" display="flex">
          {/* filtrar módulos */}
          {idModulo === 'privado' && (
            <TextField id="filtrar" label={t.T_FILTRAR_MODULOS || 'T_FILTRAR_MODULOS'} type="search" variant="outlined" className={classes.buscar} value={query} onChange={e => { setQuery(e.target.value) }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Box>

        {favoritos.length > 0 &&
          <Box width={tamaniosAcordion} className={classes.espaciadoSuperior}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1f-content" id="panel1f-header">
                <Typography variant="overline">{t.T_MODULOS_FAVORITOS || 'T_MODULOS_FAVORITOS'}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                  {favoritos.map(modulo => {
                    return (
                      <ErpListItem modulo={modulo} classes={classes} tipoCard={modulo.TipoMenu} key={modulo.NombreCanonico} />
                    )
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        }

        {modulosFiltrados.filter(e => e.Tipo === 'Área').length > 0 &&
          <>
            {bandera = true}
            <Box width={tamaniosAcordion} className={classes.espaciadoSuperior}>
              <Accordion expanded={modulosFiltrados.filter(e => e.Tipo !== 'Área').length === 0 ? true : expandido === 'panel1a'} onChange={manejaTipoModulo('panel1a')}>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography variant="overline">{tipoCard === "Menú" ? (t.T_CONSOLA_INFORMACION || 'T_CONSOLA_INFORMACION') : (t.T_AREAS || 'T_AREAS')}</Typography>
                </AccordionSummary>
                {renderDetalleAcordeon('Área', tipoCard, modulosFiltrados)}
              </Accordion>
            </Box>
          </>
        }

        {modulosFiltrados.filter(e => e.Tipo === 'Proceso').length > 0 &&
          <Box width={tamaniosAcordion} className={classes.espaciadoSuperior}>
            <Accordion expanded={modulosFiltrados.filter(e => e.Tipo !== 'Proceso').length === 0 || (!bandera && expandido === 'panel1a') ? true : expandido === 'panel1p'} onChange={manejaTipoModulo('panel1p')}>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1p-content" id="panel1p-header">
                <Typography variant="overline">{tipoCard === "Menú" ? (t.T_CONSOLA_INFORMACION || 'T_CONSOLA_INFORMACION') : (t.T_PROCESOS || 'T_PROCESOS')}</Typography>
              </AccordionSummary>
              {renderDetalleAcordeon('Proceso', tipoCard, modulosFiltrados)}
            </Accordion>
          </Box>
        }

        {modulosFiltrados.filter(e => e.Tipo === 'Servicio').length > 0 &&
          <Box width={tamaniosAcordion} className={classes.espaciadoSuperior}>
            <Accordion expanded={modulosFiltrados.filter(e => e.Tipo !== 'Servicio').length === 0 ? true : expandido === 'panel1s'} onChange={manejaTipoModulo('panel1s')}>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1s-content" id="panel1s-header">
                <Typography variant="overline">{tipoCard === "Menú" ? (t.T_CONSOLA_INFORMACION || 'T_CONSOLA_INFORMACION') : (t.T_SERVICIOS || 'T_SERVICIOS')}</Typography>
              </AccordionSummary>
              {renderDetalleAcordeon('Servicio', tipoCard, modulosFiltrados)}
            </Accordion>
          </Box>
        }
      </Grid>
    </StyledBox>
  )
}

export default ErpList