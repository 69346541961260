import React from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Grid } from '@mui/material'

const PREFIX = 'NotFound'

const classes = {
  altoMensaje: `${PREFIX}-altoMensaje`
}

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.altoMensaje}`]: {
    height: '80vh'
  }
}))

function NotFound() {
  const t = global.t || {}

  return (
    <StyledGrid container direction="row" justifyContent="center" alignItems="center" className={classes.altoMensaje} >
      <Typography variant="h4">{t.T_RECURSO_NO_ENCONTRADO || 'T_RECURSO_NO_ENCONTRADO'}</Typography>
    </StyledGrid>
  )
}

export default NotFound