import React, { Component } from 'react'

import NotificationsComponent from '../components/Notifications'

class Notifications extends Component {
  render() {
    return (
      <React.Fragment>
        <NotificationsComponent nid={this.props.match.params.nid}/>
      </React.Fragment>)
  }
}

export default Notifications