import React from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Box } from '@mui/material'
import { BottomNavigation, BottomNavigationAction } from '@mui/material'

import ErpList from '../components/ErpList'
import { SkeletonLoading } from '../components/PageLoading'
import PageError from '../components/PageError'
import MiniLoader from '../components/MiniLoader'
import api from '../api'

const PREFIX = 'Erp'
const classes = {
  menu: `${PREFIX}-menu`,
  espaciadoSuperior: `${PREFIX}-espaciadoSuperior`
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.menu}`]: {
    background: '#B0B5C1',
    width: '100%',
    height: 'auto',
    padding: 8,
  },
  [`& .${classes.espaciadoSuperior}`]: {
    marginTop: theme.spacing(2),
  }
}))

class Erp extends React.Component {
  state = {
    cargando: true,
    error: null,
    datos: undefined,
    ruta: this.props.match.url.split("/")[1] || 'publico',
    moduloId: this.props.match.params.moduloId || null,
  }

  componentDidMount() {
    this.fetchData()

    this.intervalId = setInterval(this.fetchData, process.env.REACT_APP_RECARGA_MODULOS || 30000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  fetchData = async () => {
    this.setState({ cargando: true, error: null })
    if(this.state.ruta === 'modulos') {
      try {
        const data = await api.modulos.listadoModulos(this.state.moduloId)
        this.setState({ cargando: false, datos: data })
      } catch (error) {
        this.setState({ cargando: false, error: error })
      }
    }
    else {
      try {
        const data = await api.modulos.listadoPublico(this.state.moduloId)
        this.setState({ cargando: false, datos: data })
      } catch (error) {
        this.setState({ cargando: false, error: error })
      }
    }
  }

  render() {
    const t = global.t || {}

    if (this.state.cargando === true && !this.state.datos) {
      return <SkeletonLoading origen={this.state.ruta} />
    }

    if (this.state.error) {
      clearInterval(this.intervalId)
      return <PageError error={this.state.error} />
    }

    return (
      <React.Fragment>
        <StyledGrid>
          <BottomNavigation className={classes.menu} showLabels><BottomNavigationAction label={t.T_MENU || 'T_MENU'} /></BottomNavigation>

          <Grid container direction="column" alignItems="center" justifyContent="center" item xs={12}>
            <ErpList modulos={this.state.datos} idModulo='Erp' />
            <Box width="100px" className={classes.espaciadoSuperior}>{this.state.cargando && <MiniLoader />}</Box>
          </Grid>
        </StyledGrid>
      </React.Fragment>
    )
  }
}

export default (Erp)