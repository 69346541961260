import React from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Grid, Box, Button, AppBar, Toolbar } from '@mui/material'
import { Help, YouTube } from '@mui/icons-material'
import moment from 'moment'
import 'moment/locale/es'

import api from '../api'
import ReporteErrorModal from './ReporteErrorModal'

const PREFIX = 'Footer'
const classes = {
  pie: `${PREFIX}-pie`,
  espaciadoGrid: `${PREFIX}-espaciadoGrid`,
  textoClaro: `${PREFIX}-textoClaro`,
  textoPie: `${PREFIX}-textoPie`
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.pie}`]: {
    top: 'auto',
    bottom: 0,
    backgroundColor: '#4F5F88',
    boxShadow: '0 -4px 8px 0 rgba(0, 0, 0, 0.10)',
    borderRadius: '10px 10px 0px 0px',
    zIndex: theme.zIndex.drawer + 1,
  },
  [`& .${classes.espaciadoGrid}`]: {
    paddingBottom: 48,
  },
  [`& .${classes.textoClaro}`]: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
  [`& .${classes.textoPie}`]: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(3),
  }
}))

const rutaAlmacenamiento = process.env.REACT_APP_STORAGE

class Footer extends React.Component {
  state = {
    loading: true,
    error: null,
    data: undefined,
    location: null,
    moduloId: window.location.pathname.split('lico/').pop().split('/')[0] || window.location.pathname.split('talle/').pop().split('/')[0] || window.location.pathname.split('ulos/').pop().split('/')[0] || window.location.pathname.split('lace/').pop().split('/')[0] || null,
  }

  componentDidMount() {
    if (this.state.moduloId) {
      this.fetchData()
    }
    this.setState({ location: window.location.pathname })
  }

  componentDidUpdate(prevProps, prevState) {
    if (window.location.pathname !== prevState.location) {
      if (this.state.moduloId) {
        this.fetchData()
      }
      this.setState({ data: undefined, location: window.location.pathname })
    }
  }

  fetchData = async () => {
    this.setState({ loading: true, error: null })

    try {
      const data = (await api.modulos.moduloInfo(this.state.moduloId))[0][0]
      this.setState({ loading: false, data: data })
    } catch (error) {
      this.setState({ loading: false, error: error })
    }
  }

  manejaClick = prop => {
    if (prop === 'ayuda') {
      window.open(`${rutaAlmacenamiento}/${prop}/${(this.state.data ? this.state.data.NombreCanonico + ".pdf" : "Portal.pdf")}`, "SIGU_DOC", "toolbar=no,scrollbars=yes,resizable=yes,top=100,left=500,width=800")
    } else if (prop === 'video') {
      window.open(`${rutaAlmacenamiento}/${prop}/${(this.state.data ? this.state.data.NombreCanonico + ".html" : "Portal.html")}`, "SIGU_VID", "toolbar=no,scrollbars=yes,resizable=yes,top=100,left=500,width=800")
    }
  }

  render() {
    const t = global.t || {}

    return (
      <React.Fragment>
        <StyledGrid>
          <Grid className={classes.espaciadoGrid}>
            <AppBar position="fixed" className={classes.pie}>
              <Toolbar style={{ minHeight: 50 }}>
                <Box edge="start" className={classes.textoClaro}>
                  <Typography>{this.state.data ? this.state.data.Nombre : `${t.T_SIGLAS_SISTEMA || 'T_SIGLAS_SISTEMA'} Portal`}</Typography>
                  <Typography variant="caption">{this.state.data ? (`v.${this.state.data.Version} - ${moment(this.state.data.FechaPublicacion.substring(0, 10)).format("L")}`) : ''}</Typography>
                </Box>
                <Button edge="end" className={classes.textoPie} onClick={()=>this.manejaClick("ayuda")} startIcon={<Help />}>{t.T_AYUDA || 'T_AYUDA'}</Button>
                <Button className={classes.textoPie} onClick={()=>this.manejaClick("video")} startIcon={<YouTube />}>{t.T_VIDEO || 'T_VIDEO'}</Button>
                <ReporteErrorModal modulo={this.state.data}/>
              </Toolbar>
            </AppBar>
          </Grid>
        </StyledGrid>
      </React.Fragment>
    )
  }
}

export default (Footer)