import React from 'react'
import jwt from 'jsonwebtoken'
import Cookies from 'universal-cookie'
import { Badge, Popover, List, ListItemIcon, ListItemText, ListItemButton, Menu, MenuItem, IconButton } from '@mui/material'
import { Home, AllInbox, Notifications as NotificationIcon, Error, ViewList, ViewModule, List as ListIcon, Room as RoomIcon,
  AccountTree, AccountBalance, Person, AccountCircle, Fingerprint, ExitToApp, Payment, Visibility, Info, Assignment, AssignmentTurnedIn,
  Description, ShoppingCart, DateRange, Timeline, School, WrapText, ArrowBack } from '@mui/icons-material'
import { Alert, AlertTitle } from '@mui/material'

import LogOut from '../components/LogOut'
import { SkeletonLoading } from '../components/PageLoading'

const cookies = new Cookies()
const token = cookies.get('_siguid') ? cookies.get('_siguid') : null
let datosUsuario

// Obtiene los datos de la sesión
try {
  datosUsuario = jwt.decode(token.split(' ')[1])
} catch (error) {
  datosUsuario = []
}

// Verifica las credenciales del usuario.
class AuthToken extends React.Component {
  state = {
    mensajeError: ''
  }

  async componentDidMount() {
    if (!token && (window.location.pathname.split('/')[1] === 'modulos')) {
      window.location.href = `${process.env.REACT_APP_LOGIN_PAGE}/${window.location.pathname.replaceAll('/', 'ßÞ')}`
    }
    if (token) {
      try {
        const response = await fetch(`${process.env.REACT_APP_LOGIN_API}/verify`, {
          method: 'GET',
          headers: new Headers({
            authorization: token,
          }),
        })
        if (response.status === 440) {
          window.location.href = `${process.env.REACT_APP_LOGIN_PAGE}/${window.location.pathname.replaceAll('/', 'ßÞ')}`
          LogOut(datosUsuario?.uid || "-1", token.split(' ')[1])
          this.setState({ mensajeError: response.status })
        } else {
          if (response.status !== 200) {
            LogOut("-1", token.split(' ')[1])
            this.setState({ mensajeError: response.status })
          }
        }
      }
      catch (error) {
        LogOut("-1", token.split(' ')[1])
      }
    }
  }

  render() {
    const t = global.t || {}

    return (
      <React.Fragment>
        {this.state.mensajeError !== '' &&
          <Alert elevation={6} severity="error">
            <AlertTitle>{t.T_ERROR || 'T_ERROR'}</AlertTitle>{this.state.mensajeError}
          </Alert>
        }
      </React.Fragment>
    )
  }
}

// Carga las funciones básicas de la opción de Notificaciones en la barra de menú superior.
class Notification extends React.Component {
  state = {
    notificaciones: 0,
    mensajeErrorAPI: '',
    anchorEl: null,
    estadoMenu: null,
  }

  async componentDidMount() {
    this.fetchData()
    this.intervalId = setInterval(this.fetchData, process.env.REACT_APP_RECARGA_NOTIFICACION || 60000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  manejaAperturaPopover = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  manejaCierrePopover = () => {
    this.setState({ anchorEl: null })
  }

  manejaAperturaMenu = (event) => {
    this.setState({ estadoMenu: event.currentTarget })
  }

  manejaCierreMenu = () => {
    this.setState({ estadoMenu: null })
  }

  manejaNotificacion = async (nid) => {
    const t = global.t || {}

    if (!token && (window.location.pathname.split('/')[1] === 'modulos')) {
      window.location.href = `${process.env.REACT_APP_LOGIN_PAGE}/${window.location.pathname.replaceAll('/', 'ßÞ')}`
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_PORTAL_API}/notify/${nid}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: token,
        }
      })
      await response.json()
      if (response.status === 200) {
        window.location.href = `/notifications/${nid}`
      } else {
        return { mensajeErrorAPI: (t.T_MENSAJE_ERROR_ACTUALIZAR_NOTIFICACION || 'T_MENSAJE_ERROR_ACTUALIZAR_NOTIFICACION') }
      }
    } catch (error) {
      return { mensajeErrorAPI: (t.T_MENSAJE_ERROR_ACTUALIZAR_NOTIFICACION || 'T_MENSAJE_ERROR_ACTUALIZAR_NOTIFICACION') }
    }
  }

  fetchData = async () => {
    const t = global.t || {}

    if (token) {
      try {
        const response = await fetch(`${process.env.REACT_APP_PORTAL_API}/notify`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            authorization: token,
          }
        })
        const datos = await response.json()
        if (response.status === 200 && datos.body) {
          this.setState({ notificaciones: datos.body, mensajeErrorAPI: '' })
        }
        else {
          this.setState({ notificaciones: "!", mensajeErrorAPI: (t.T_MENSAJE_ERROR_RECIBIR_NOTIFICACION || 'T_MENSAJE_ERROR_RECIBIR_NOTIFICACION') })
        }
      }
      catch (error) {
        this.setState({ notificaciones: "!", mensajeErrorAPI: (t.T_ERROR_500 || 'T_ERROR_500') })
      }
    }
  }

  render() {
    const t = global.t || {}
    const open = Boolean(this.state.anchorEl)
    const menuId = 'menu-notificaciones'
    return (
      <React.Fragment>
        {this.props.tipoMenu !== "escritorio" &&
          <MenuItem onClick={this.manejaAperturaMenu} onMouseEnter={this.manejaAperturaPopover} onMouseLeave={this.manejaCierrePopover}>
            <IconButton aria-label={t.T_MOSTRAR_NOTIFICACIONES || 'T_MOSTRAR_NOTIFICACIONES'} color="inherit" className={this.props.className} size="large">
              <Badge badgeContent={this.state.mensajeErrorAPI !== '' ? this.state.notificaciones : Object.keys(this.state.notificaciones).length} color="error">
                <NotificationIcon />
              </Badge>
            </IconButton>
            Notificaciones
          </MenuItem>
        }
       {this.props.tipoMenu === "escritorio" &&
          <IconButton aria-label={t.T_MOSTRAR_NOTIFICACIONES || 'T_MOSTRAR_NOTIFICACIONES'} color="inherit" className={this.props.className} onClick={this.manejaAperturaMenu} size="large">
            <Badge badgeContent={this.state.mensajeErrorAPI !== '' ? this.state.notificaciones : Object.keys(this.state.notificaciones).length} color="error" onMouseEnter={this.manejaAperturaPopover} onMouseLeave={this.manejaCierrePopover}>
              <NotificationIcon />
            </Badge>
          </IconButton>
        }
        {this.state.mensajeErrorAPI !== '' &&
          <Popover anchorEl={this.state.anchorEl} open={open} onClose={this.manejaCierrePopover} style={{pointerEvents: 'none'}} disableRestoreFocus anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }}>
            <Alert elevation={6} severity="error">{this.state.mensajeErrorAPI}</Alert>
          </Popover>
        }
        {this.state.mensajeErrorAPI === '' && Object.keys(this.state.notificaciones).length > 0 &&
          <Menu id={menuId} anchorEl={this.state.estadoMenu} open={Boolean(this.state.estadoMenu)} onClose={this.manejaCierreMenu} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
            {this.state.notificaciones.map((datos) => (
              <MenuItem key={datos.NotificacionId} divider onClick={() => this.manejaNotificacion(datos.NotificacionId)}>
                <ListItemIcon>
                  <Assignment />
                </ListItemIcon>
                <ListItemText primary={datos.Nombre} secondary={datos.Descripcion} />
              </MenuItem>
            ))}
          </Menu>
        }
      </React.Fragment>
    )
  }
}

// Carga la lista de opciones del bloque lateral.
class MenuItemList extends React.Component {
  state = {
    moduloId: window.location.pathname.split('lico/').pop().split('/')[0] || window.location.pathname.split('talle/').pop().split('/')[0] || window.location.pathname.split('ulos/').pop().split('/')[0] || window.location.pathname.split('lace/').pop().split('/')[0] || 0,
    menuItems: {},
    listaIconos: [
      {id:'inicio', icono:<Home />}, {id:'cuenta', icono:<AccountCircle />}, {id:'usuario', icono:<Person />}, {id:'opciones', icono:<ViewModule />},
      {id:'seguridad', icono:<Fingerprint />}, {id:'salir', icono:<ExitToApp />}, {id:'bandeja', icono:<AllInbox />}, {id:'pago', icono:<Payment />},
      {id:'gestion', icono:<Visibility />}, {id:'info', icono:<Info />}, {id:'evaluacion', icono:<AssignmentTurnedIn />}, {id:'flujo', icono:<WrapText />},
      {id:'catalogo', icono:<Description />}, {id:'compra', icono:<ShoppingCart />}, {id:'calendario', icono:<DateRange />}, {id:'menus', icono:<ViewList />},
      {id:'seguimiento', icono:<Timeline />}, {id:'principal', icono:<AccountBalance />}, {id:'academia', icono:<School />}, {id:'regresar', icono:<ArrowBack />},
      {id:'ubicacion', icono:<RoomIcon />}, {id:'esquema', icono:<AccountTree />}, {id:'error', icono:<Error color='error' />},
    ]
  }

  async componentDidMount() {
    const t = global.t || {}
    if (token) {
      try {
        const response = await fetch(`${process.env.REACT_APP_PORTAL_API}/menuOpt/${this.state.moduloId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            authorization: token,
          }
        })
        const datos = await response.json()
        if (response.status === 200 && datos.body) {
          this.setState({ menuItems: [{Icono: 'inicio', Descripcion: (t.T_PAGINA_INICIO || 'T_PAGINA_INICIO'), Enlace: '/modulos'}]})
          datos.body.length > 0 &&
            this.setState({menuItems: [...datos.body]})
        }
        else {
          this.setState({ menuItems: [{Icono: 'error', Descripcion: (t.T_MENSAJE_ERROR_CARGANDO_MENU || 'T_MENSAJE_ERROR_CARGANDO_MENU'), Enlace: '#'}] })
        }
      }
      catch (error) {
        this.setState({ menuItems: [{Icono: 'error', Descripcion: (t.T_MENSAJE_ERROR_SERVIDOR || 'T_MENSAJE_ERROR_SERVIDOR'), Enlace: '#'}] })
      }
    }
    else {
      this.setState({ menuItems: [{Icono: 'inicio', Descripcion: (t.T_PAGINA_INICIO || 'T_PAGINA_INICIO'), Enlace: '/'}] })
    }
  }

  render() {
    const t = global.t || {}
    if (Object.keys(this.state.menuItems).length > 0) {
      return (
        <React.Fragment>
          <List>
            {(this.state.menuItems).map(e =>
              <ListItemButton component="a" href={e.Enlace} key={e.Descripcion}>
                <ListItemIcon>{this.state.listaIconos.find(el => el.id === e.Icono) ? this.state.listaIconos.find(el => el.id === e.Icono)["icono"] : <ListIcon />}</ListItemIcon>
                <ListItemText primary={e.Descripcion} />
              </ListItemButton>
            )}
            {this.state.moduloId > 0 &&
              <ListItemButton component="a" onClick={()=>{this.props.ruta.goBack()}}>
                <ListItemIcon>{this.state.listaIconos.find(el => el.id === 'regresar') ? this.state.listaIconos.find(el => el.id === 'regresar')["icono"] : <ListIcon />}</ListItemIcon>
                <ListItemText primary={t.T_REGRESAR || 'T_REGRESAR'} />
              </ListItemButton>
            }
          </List>
        </React.Fragment>
      )
    }
    return <SkeletonLoading origen='menuLateral' />
  }
}

export {
  AuthToken,
  Notification,
  MenuItemList,
}