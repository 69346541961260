import React from 'react'
import { styled } from '@mui/material/styles'
import jwt from 'jsonwebtoken'
import Cookies from 'universal-cookie'
import Parser from 'html-react-parser'
import { Typography, Box, Grid, Link } from '@mui/material'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'

import LogOut from '../components/LogOut'
import PageError from '../components/PageError'
import { PageLoading } from '../components/PageLoading'

const PREFIX = 'Notifications'
const classes = {
  espaciadoSuperior: `${PREFIX}-espaciadoSuperior`
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.espaciadoSuperior}`]: {
    marginTop: theme.spacing(2),
  }
}))

const cookies = new Cookies()
const token = cookies.get('_siguid') ? cookies.get('_siguid').split(' ')[1] : null
const tamaniosAcordion = { xs: "100%", sm: "100%", lg: "1100px", xl: "1400px" }

try {
  jwt.decode(token)
} catch (error) {
  LogOut("-1", token)
}
const datosUsuario = jwt.decode(token)

class NotificationsComponent extends React.Component {
  state = {
    cargando: false,
    notificacion: 0,
    mensajeErrorAPI: ''
  }

  async componentDidMount() {
    this.fetchData()
  }

  fetchData = async () => {
    const t = global.t || {}
    this.setState({ cargando: true })

    if (datosUsuario) {
      try {
        const response = await fetch(`${process.env.REACT_APP_PORTAL_API}/notify/showNotify/${this.props.nid}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            authorization: token,
          }
        })
        const datos = await response.json()
        if (response.status === 200 && datos.body) {
          this.setState({ cargando: false, notificacion: datos.body, mensajeErrorAPI: '' })
        }
        else {
          this.setState({ cargando: false, mensajeErrorAPI: `${t.T_MENSAJE_ERROR_RECIBIR_NOTIFICACION || 'T_MENSAJE_ERROR_RECIBIR_NOTIFICACION'}: ${t.T_MENSAJE_SIN_PERMISOS || 'T_MENSAJE_SIN_PERMISOS'}` })
        }
      } catch (error) {
        this.setState({ cargando: false, mensajeErrorAPI: `${t.T_MENSAJE_ERROR_RECIBIR_NOTIFICACION || 'T_MENSAJE_ERROR_RECIBIR_NOTIFICACION'}` })
      }
    }
    else {
      // Si se indica que los usuarios que no están registrados no pueden tener notificaciones, en la línea siguiente debería redirigirse al login
      this.setState({ cargando: false, mensajeErrorAPI: `${t.T_MENSAJE_ERROR_USUARIO || 'T_MENSAJE_ERROR_USUARIO'}: ${t.T_MENSAJE_SIN_PERMISOS || 'T_MENSAJE_SIN_PERMISOS'}` })
    }
  }

  render() {
    const t = global.t || {}

    if (this.state.cargando) {
      return <PageLoading />
    }
    if (this.state.mensajeErrorAPI !== '') {
      return <PageError error={{message: this.state.mensajeErrorAPI}} />
    }
    return (
      <React.Fragment>
        <StyledGrid>
          {this.state.mensajeErrorAPI === '' && Object.keys(this.state.notificacion).length > 0  && (
            <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.espaciadoSuperior}>
              <Box width={tamaniosAcordion} className={classes.espaciadoSuperior}>
                <Accordion expanded>
                  <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant="overline">{t.T_NOTIFICACIONES || 'T_NOTIFICACIONES'}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                      <Box>
                        <Typography variant="h5" component="h2">{this.state.notificacion[0].Nombre}</Typography>
                        {
                          this.state.notificacion[0].Descripcion.split("\n").map(function(valor, indice) {
                            if (valor.includes("ßa")) {
                              return (
                                <Link key={indice} href={valor.split("ßa")[1] || '#'} underline='always' variant="h6">{t.T_VER_INFORMACION || 'T_VER_INFORMACION'}</Link>
                              )
                            } else {
                              return (
                                <Typography color="textSecondary" variant="span" key={indice} gutterBottom>{Parser(valor)}</Typography>
                              )
                            }
                          })
                        }
                        <Typography color="textSecondary" variant="caption" display="block" gutterBottom>{ this.state.notificacion[0].Estado === 1 ? (t.T_SIN_REVISAR || 'T_SIN_REVISAR') : (t.T_NOTIFICACION_REVISADA || 'T_NOTIFICACION_REVISADA') }</Typography>
                      </Box>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
          )}
        </StyledGrid>
      </React.Fragment>
    )
  }
}

export default (NotificationsComponent)
