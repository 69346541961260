async function cargaDatosMaestroNacimientos(identificacion) {
  const response = await fetch(`${global.MiscUrl}/maestro-nacimientos/${identificacion}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
  })
  .catch(err => {
    throw (err)
  })
  const datos = await response.json()
  return datos
}

export default cargaDatosMaestroNacimientos