import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import jwt from 'jsonwebtoken'
import clsx from 'clsx'
import axios from 'axios'
import Cookies from 'universal-cookie'
import ReCAPTCHA from 'react-google-recaptcha'
import { BugReport, MailOutline, AccountCircle, Assignment, Phone, Dialpad, AlternateEmail, Delete as DeleteIcon } from '@mui/icons-material'
import { Grid, Box, Button, IconButton, TextField, InputAdornment, Typography, Snackbar, CircularProgress } from '@mui/material'
import { List, ListItem, ListItemText, ListItemSecondaryAction, Divider } from '@mui/material'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Alert, AlertTitle } from '@mui/material'
import { red } from '@mui/material/colors'

import MaestroNacimientos from '../components/MaestroNacimientos'

const PREFIX = 'ReporteErrorModal'
const classes = {
  textoPie: `${PREFIX}-textoPie`,
  margin: `${PREFIX}-margin`,
  textField: `${PREFIX}-textField`,
  textFieldTel: `${PREFIX}-textFieldTel`,
  textFieldExt: `${PREFIX}-textFieldExt`,
  colorIcono: `${PREFIX}-colorIcono`
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.textoPie}`]: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(3),
  },
  [`& .${classes.margin}`]: {
    margin: theme.spacing(1),
  },
  [`& .${classes.textField}`]: {
    width: '444px',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  [`& .${classes.textFieldTel}`]: {
    width: 300,
  },
  [`& .${classes.textFieldExt}`]: {
    width: 128,
  },
  [`& .${classes.colorIcono}`]: {
    color: red[800],
  }
}))

function ReporteErrorModal(prop) {
  const t = global.t || {}
  const datosMensajes = {
    mensajeErrorAPI: '',
    mensajeAPI: '',
  }
  const datosIniciales = {
    uid: 1,
    identificacion: '',
    nombre: '',
    remitente: '',
    telefono: '',
    extension: '',
    asunto: '',
    detalleLoc: '',
    detalleBug: '',
    resultadoEsperado: '',
    navegador: '',
    archivosEvidencia: [],
    archivosIds: [],
    adjuntosCorreo: [],
    datosError: {},
    destinatario: '',
  }
  const limpiarDatos = {
    asunto: '',
    detalleLoc: '',
    detalleBug: '',
    resultadoEsperado: '',
    navegador: '',
    archivosEvidencia: [],
    archivosIds: [],
    adjuntosCorreo: [],
    datosError: {},
    destinatario: '',
  }

  const [abierto, cargaAbierto] = React.useState(false)
  const [bloquearBoton, cargaEstadoBoton] = React.useState(false)
  const [values, cargaValores] = React.useState(datosIniciales)
  const [mensajes, cargaMensajes] = React.useState(datosMensajes)

  //variables uso de ReCAPTCHA
  const captchaRef = React.useRef(null)
  const [captchaValido, cargaCaptchaValido] = React.useState()

  const cookies = new Cookies()
  const token = cookies.get('_siguid') ? cookies.get('_siguid').split(' ')[1] : null
  let datosUsuario

  // Obtiene los datos de la sesión
  try {
    datosUsuario = jwt.decode(token)
  } catch (error) {
    datosUsuario = []
  }

  // Carga los datos del usuario si tiene una sesión abierta
  useEffect(() => {
    datosUsuario &&
      cargaValores({...values, uid: datosUsuario.uid, identificacion: datosUsuario.cedula, nombre: datosUsuario.nombreCompleto, remitente: datosUsuario.email})
    //El siguiente comentario, elimina la advertencia que existe una dependencia faltante en React Hook useEffect.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Maneja el cierre de la alerta
  const manejaCierreAlerta = () => {
    cargaMensajes(datosMensajes)
  }

  // Busca los datos de la persona por el número de identificación dentro del servicio de misc en el maestro de nacimientos
  const buscaMaestroNacimientos = (prop) => async (event) => {
    const id = prop || event.target.value
    if (id.length >= 9) {
      await MaestroNacimientos(id)
      .then(async (datosPersona) => {
        datosPersona.body.length === 0 && cargaMensajes({ mensajeAPI: '', mensajeErrorAPI: `identificación: ${t.T_ERROR_IDENTIFICACION_TSE || 'T_ERROR_IDENTIFICACION_TSE'}` })
        //Se almacenan los valores de la persona
        datosPersona.body.length > 0 &&
          cargaValores({...values, nombre: `${datosPersona.body[0].Nombre} ${datosPersona.body[0].PrimerApellido} ${datosPersona.body[0].SegundoApellido}`})
          cargaMensajes({mensajeAPI: '', mensajeErrorAPI: datosPersona.body[0].Difunto ? (t.T_MENSAJE_PERSONA_DIFUNTA || 'T_MENSAJE_PERSONA_DIFUNTA') : ''})
      })
      .catch(err => {
        console.error(`Error cargando los datos: ${err}`)
      })
    }
  }

  // Controla abrir el modal
  const manejaAbrir = () => {
    cargaMensajes(datosMensajes)
    cargaAbierto(true)
  }

  // Controla cerrar el modal
  const manejaCerrar = () => {
    cargaAbierto(false)
    cargaEstadoBoton(false)
  }

  //Verificación del Captcha
  const manejaCambioCaptcha = () => {
    if (captchaRef.current.getValue()) {
     cargaCaptchaValido(true)
    }
  }
  const manejaExpiracionCaptcha = () => {
   cargaCaptchaValido(false)
  }

  // Maneja los cambios en los campos de texto y combos, verifica que exista conexión con el servicio misc para cargar los combos
  const manejaCambio = (prop) => (event) => {
    cargaMensajes(datosMensajes)
    // Valida el formato del teléfono
    const regexTelefono = /^(2|4|5|6|7|8)([0-9])*$/
    const regexExtension = /^[0-9\b]+$/
    if (prop === 'telefono' && event.target.value !== "" && !regexTelefono.test(event.target.value)) {return}
    if (prop === 'extension' && event.target.value !== "" && !regexExtension.test(event.target.value)) {return}
    cargaValores({ ...values, [prop]: event.target.value })
  }

  // Maneja el cambio en la lista de archivos.
  const manejaArchivos = (event) => {
    cargaMensajes(datosMensajes)
    let temp ={}
    let arreglo = []
    Object.keys(event.target.files).map(indice =>
      arreglo = arreglo.concat((values['archivosEvidencia'].concat(event.target.files[indice])).filter(elemento => temp[elemento.name] ? false : temp[elemento.name] = true))
    )
    cargaValores({...values, archivosEvidencia:arreglo})
  }

  // Procesa las imágenes para obtener el formato que se necesita en el misc
  const procesarImagenes = async(objeto) => {
    return new Promise((res) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
          res({
              file: {name:objeto.name, size:objeto.size, path:objeto.name, type:objeto.type, lastModified:objeto.lastModified},
              data: reader.result,
          })
      })
      reader.readAsDataURL(objeto)
    })
  }

  // Sube los archivos al servicio de misc
  const almacenarArchivos = async (objeto) => {
    const newImagesPromises = []
    for (let i = 0; i < objeto.length; i++) {
        newImagesPromises.push(procesarImagenes(objeto[i]))
    }
    values.adjuntosCorreo = await Promise.all(newImagesPromises)
    return
  }

  // Elimina archivos del arreglo
  const eliminarArchivos = async (archivo) => {
    cargaValores({...values, archivosEvidencia:values['archivosEvidencia'].filter((elemento) => elemento.name !== archivo)})
  }

  // Envía el reporte de error
  const manejaReporteError = async() => {
    let correoReporte = process.env.REACT_APP_REPORTE_CORREO_DEFECTO
    let notificar = false
    cargaMensajes(datosMensajes)
    if (prop.modulo) {
      correoReporte =  prop.modulo.CorreoReporte === '-' ? correoReporte : prop.modulo.CorreoReporte
      notificar = prop.modulo.Encargado
    }
    if (values.archivosEvidencia.length === 0) {
      cargaMensajes({ mensajeAPI: '', mensajeErrorAPI: (t.T_MENSAJE_ERROR_ADJUNTAR_EVIDENCIA || 'T_MENSAJE_ERROR_ADJUNTAR_EVIDENCIA') })
    } else {
      if (!values.identificacion || !values.nombre || values.telefono.length < 8 || !values.detalleLoc || !values.detalleBug || !values.resultadoEsperado) {
        cargaMensajes({ mensajeAPI: '', mensajeErrorAPI: `${t.T_INFORMACION || 'T_INFORMACION'}: ${!values.identificacion ? 'cédula o identificación, ' : ''} ${!values.nombre ? 'nombre completo, ' : ''} ${!values.remitente ? 'correo, ' : ''} ${values.telefono.length < 8 ? 'teléfono, ' : ''} ${!values.asunto ? 'asunto, ' : ''} ${!values.detalleLoc ? 'detalle localización, ' : ''} ${!values.detalleBug ? 'detalle del incidente o bug, ' : ''} ${!values.resultadoEsperado ? 'resultado esperado, ' : ' '}${t.T_ES_OBLIGATORIA || 'T_ES_OBLIGATORIA'}` })
      } else {
        cargaEstadoBoton(true)
        await almacenarArchivos(values.archivosEvidencia)
        const urlCorreo = `${global.MiscUrl}/correo`
        const repositorio = prop.modulo?.Repositorio && prop.modulo?.Repositorio !== '-' ? `<p>${t.T_REPOSITORIO || 'T_REPOSITORIO'}:<br />${prop.modulo.Repositorio}</p>` : ''
        values.navegador = window.navigator.userAgent
        values.datosError = document.getElementById('portal').ownerDocument
        values.destinatario = correoReporte
        const mensajeCorreo = `<p>${values.nombre}, ${t.T_IDENTIFICACION || 'T_IDENTIFICACION'} ${values.identificacion}:</p><p>${t.T_TELEFONO_CONTACTO || 'T_TELEFONO_CONTACTO'}: ${values.telefono}${values.extension ? ` - ${t.T_EXTENSION_TELEFONO_CONTACTO || 'T_EXTENSION_TELEFONO_CONTACTO'}: `.concat(values.extension) : ''}</p><p>${t.T_DETALLE_LOCALIZACION_ERROR || 'T_DETALLE_LOCALIZACION_ERROR'}:<br />${values.detalleLoc}</p>
          <p>${t.T_DETALLE_INCIDENTE || 'T_DETALLE_INCIDENTE'}:<br />${values.detalleBug}</p>
          <p>${t.T_DETALLE_RESULTADO_ESPERADO || 'T_DETALLE_RESULTADO_ESPERADO'}:<br />${values.resultadoEsperado}</p>
          ${repositorio}`
        axios.post(urlCorreo, {uid: values.uid, remitente: values.remitente, destinatario: values.destinatario, asunto: values.asunto, mensaje: mensajeCorreo, carpetaAdjuntos: `reporte/archivosEvidencia/${values.identificacion}-${prop.modulo ? prop.modulo.Nombre : `${t.T_SIGLAS_SISTEMA || 'T_SIGLAS_SISTEMA'} Portal`}`, archivosAdjuntos: values.adjuntosCorreo, notificar: notificar}, {
          withCredentials: true,
          headers:{origen: process.env.REACT_APP_SEGURO === 'production' ? window.location.hostname : `181${window.location.hostname.split('.181')[1]}`}
        })
        .then(response => {
          if (response.status === 200) {
            axios.post(`${process.env.REACT_APP_PORTAL_API}/reporte`, {uid: values.uid, identificacion: values.identificacion, correoId: response.data.ids, navegador: values.navegador, datosError: values.datosError}, {
              withCredentials: true,
            })
            cargaValores({...values, ...limpiarDatos})
            cargaMensajes({ mensajeAPI: response.data.body, mensajeErrorAPI: '' })
            setTimeout(() => {manejaCerrar()}, 1000)
            return
          }
        })
        .catch(err => {
          cargaEstadoBoton(false)
          cargaMensajes({ mensajeAPI: '', mensajeErrorAPI: !err.response ? (t.T_ERROR_500 || 'T_ERROR_500') : err.response.data.error })
          return
        })
      }
    }
  }

  return (
    <React.Fragment>
      <StyledGrid>
        <Button id="reporte" align="center" className={classes.textoPie} onClick={manejaAbrir} startIcon={<BugReport />}>{t.T_REPORTE_ERROR || 'T_REPORTE_ERROR'}</Button>
      </StyledGrid>
      <Dialog open={abierto} aria-labelledby="modal-reporte-error" aria-describedby="descripcion-mmodal-reporte-error">
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={mensajes.mensajeErrorAPI !== '' ? true : false}>
          <Alert elevation={6} severity='error' onClose={manejaCierreAlerta}>
            <AlertTitle>{t.T_ERROR || 'T_ERROR'}</AlertTitle>{mensajes.mensajeErrorAPI}
          </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={mensajes.mensajeAPI !== '' ? true : false} onClose={manejaCierreAlerta}>
          <Alert elevation={6} severity='success' onClose={manejaCierreAlerta}>
            <AlertTitle>{t.T_CORRECTO || 'T_CORRECTO'}</AlertTitle>{mensajes.mensajeAPI}
          </Alert>
        </Snackbar>
        <DialogTitle id="modal-reporte-error">{`${t.T_REPORTE_FALLOS_INCIDENCIAS || 'T_REPORTE_FALLOS_INCIDENCIAS'}: `}
          <Box fontWeight="bold" display="inline">{prop.modulo ? prop.modulo.Nombre : `${t.T_SIGLAS_SISTEMA || 'T_SIGLAS_SISTEMA'} Portal`}</Box>
          {prop.modulo?.Repositorio && prop.modulo?.Repositorio !== '-' &&
            <Typography variant="caption" display="block"><Box fontWeight="bold" display="inline">{`${t.T_REPOSITORIO || 'T_REPOSITORIO'}: `}</Box>{prop.modulo?.Repositorio}</Typography>
          }
        </DialogTitle>
        <DialogContent>
          <StyledGrid>
            {datosUsuario &&
              <Box>
                <Typography variant="caption" display="block"><Box fontWeight="bold" display="inline">{`${t.T_USUARIO || 'T_USUARIO'}: `}</Box>{`${values.identificacion}`}</Typography>
                <Typography variant="caption" display="block"><Box fontWeight="bold" display="inline">{`${t.T_NOMBRE_PERSONA || 'T_NOMBRE_PERSONA'}: `}</Box>{`${values.nombre}`}</Typography>
                <Typography variant="caption" display="block"><Box fontWeight="bold" display="inline">{`${t.T_CORREO || 'T_CORREO'}: `}</Box>{`${values.remitente}`}</Typography>
              </Box>
            }
            {!datosUsuario &&
              <Box>
                <TextField autoFocus required label={`${t.T_IDENTIFICACION || 'T_IDENTIFICACION'}: `} id='inputIdentificacion' className={clsx(classes.margin, classes.textField)} value={values.identificacion} onChange={manejaCambio('identificacion')} onBlur={buscaMaestroNacimientos()}
                  error={/identificación|cédula/.test(mensajes.mensajeErrorAPI)} helperText={/identificación|cédula/.test(mensajes.mensajeErrorAPI) ? mensajes.mensajeErrorAPI : ''}
                  inputProps={{ maxLength: 20 }}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'><AccountCircle /></InputAdornment>,
                  }}
                  variant='outlined'
                />
                <TextField required label={`${t.T_NOMBRE_PERSONA || 'T_NOMBRE_PERSONA'}: `} id='inputNombre' className={clsx(classes.margin, classes.textField)} value={values.nombre} onChange={manejaCambio('nombre')}
                  error={/nombre/.test(mensajes.mensajeErrorAPI)} helperText={/nombre/.test(mensajes.mensajeErrorAPI) ? mensajes.mensajeErrorAPI : ''}
                  inputProps={{ maxLength: 50 }}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'><Assignment /></InputAdornment>,
                  }}
                  variant='outlined'
                />
                <TextField required label={`${t.T_CORREO || 'T_CORREO'}: `} id='inputCorreo' className={clsx(classes.margin, classes.textField)} value={values.remitente} onChange={manejaCambio('remitente')}
                  error={RegExp(`^(?=.*\\bcorreo\\b)(?=.*\\b${values.remitente.replace(/[^a-zA-Z ]/g, "")}\\b)`, 'u').test(mensajes.mensajeErrorAPI.replace(/[^a-zA-Z ]/g, ""))} helperText={RegExp(`^(?=.*\\bcorreo\\b)(?=.*\\b${values.remitente.replace(/[^a-zA-Z ]/g, "")}\\b)`, 'u').test(mensajes.mensajeErrorAPI.replace(/[^a-zA-Z ]/g, "")) ? mensajes.mensajeErrorAPI : ''}
                  inputProps={{ maxLength: 100 }}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'><AlternateEmail /></InputAdornment>,
                  }}
                  type='email' variant='outlined'
                />
              </Box>
            }
            <TextField required label={`${t.T_TELEFONO_CONTACTO || 'T_TELEFONO_CONTACTO'}: `} id='inputTelefono' className={clsx(classes.margin, classes.textFieldTel)} value={values.telefono} onChange={manejaCambio('telefono')}
              error={/telfono/.test(mensajes.mensajeErrorAPI.replace(/[^a-zA-Z0-9 ]/g, ""))} helperText={/telfono/.test(mensajes.mensajeErrorAPI.replace(/[^a-zA-Z0-9 ]/g, "")) ? mensajes.mensajeErrorAPI : ''}
              inputProps={{ maxLength: 8 }}
              InputProps={{
                endAdornment: <InputAdornment position='end'><Phone /></InputAdornment>,
              }}
              variant='outlined'
            />
            <TextField label={t.T_EXTENSION_TELEFONO_CONTACTO || 'T_EXTENSION_TELEFONO_CONTACTO'} id='inputExtension' className={clsx(classes.margin, classes.textFieldExt)} value={values.extension} onChange={manejaCambio('extension')}
              inputProps={{ maxLength: 4 }}
              InputProps={{
                endAdornment: <InputAdornment position='end'><Dialpad /></InputAdornment>,
              }}
              variant='outlined'
            />
            <TextField required label={`${t.T_ASUNTO || 'T_ASUNTO'}: `} id='inputAsunto' className={clsx(classes.margin, classes.textField)} value={values.asunto} onChange={manejaCambio('asunto')}
              error={/asunto/.test(mensajes.mensajeErrorAPI)} helperText={/asunto/.test(mensajes.mensajeErrorAPI) ? mensajes.mensajeErrorAPI : ''}
              inputProps={{ maxLength: 200 }}
              variant='outlined'
            />
            <TextField required label={`${t.T_DETALLE_LOCALIZACION_ERROR || 'T_DETALLE_LOCALIZACION_ERROR'}: `} id='inputDetalleLoc' className={clsx(classes.margin, classes.textField)} value={values.detalleLoc} onChange={manejaCambio('detalleLoc')}
              error={/localizaci/.test(mensajes.mensajeErrorAPI)} helperText={/localizaci/.test(mensajes.mensajeErrorAPI) ? mensajes.mensajeErrorAPI : ''}
              inputProps={{ maxLength: 20000 }}
              variant='outlined' multiline maxRows={4} minRows={4}
            />
            <TextField required label={`${t.T_DETALLE_INCIDENTE || 'T_DETALLE_INCIDENTE'}: `} id='inputDetalleBug' className={clsx(classes.margin, classes.textField)} value={values.detalleBug} onChange={manejaCambio('detalleBug')}
              error={/incidente/.test(mensajes.mensajeErrorAPI)} helperText={/incidente/.test(mensajes.mensajeErrorAPI) ? mensajes.mensajeErrorAPI : ''}
              inputProps={{ maxLength: 20000 }}
              variant='outlined' multiline maxRows={4} minRows={4}
            />
            <TextField required label={`${t.T_DETALLE_RESULTADO_ESPERADO || 'T_DETALLE_RESULTADO_ESPERADO'}: `} id='inputResultadoEsperado' className={clsx(classes.margin, classes.textField)} value={values.resultadoEsperado} onChange={manejaCambio('resultadoEsperado')}
              error={/esperado/.test(mensajes.mensajeErrorAPI)} helperText={/esperado/.test(mensajes.mensajeErrorAPI) ? mensajes.mensajeErrorAPI : ''}
              inputProps={{ maxLength: 20000 }}
              variant='outlined' multiline maxRows={4} minRows={4}
            />
            {values.archivosEvidencia.length > 0 &&
              Object.values(values.archivosEvidencia).map(e =>
                <List component="nav" key={e.name}>
                  <ListItem >
                    <ListItemText
                      primary={`${t.T_IMAGEN || 'T_IMAGEN'}: ${e.name}`}
                      secondary={`${t.T_TAMANIO || 'T_TAMANIO'}: ${
                        Math.round(e.size/1024) < 1 ? e.size+'Bytes' :
                        Math.round(e.size/(1024*1024)) < 1 ? Math.round(e.size/1024)+'KB' :
                        Math.round(e.size/(1024*1024*1024)) < 1 ? Math.round(e.size/(1024*1024))+'MB' :
                        Math.round(e.size/(1024*1024*1024))+'GB'}`
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label={`${t.T_ELIMINAR || 'T_ELIMINAR'}: ${e.name}`} onClick={() => window.confirm(`${t.T_PREGUNTA_ELIMINAR_REGISTRO || 'T_PREGUNTA_ELIMINAR_REGISTRO'}: ${e.name}`,) && eliminarArchivos(e.name)} size="large">
                        <DeleteIcon className={classes.colorIcono} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </List>
              )
            }
            <Box>
              <Typography variant='caption'>{`${t.T_MENSAJE_MODAL_REPORTE_ERROR || 'T_MENSAJE_MODAL_REPORTE_ERROR'}`}</Typography>
            </Box>
            <Button variant="contained" component="label" className={classes.margin}>{`${t.T_SUBIR_IMAGEN_EVIDENCIA || 'T_SUBIR_IMAGEN_EVIDENCIA'}` }
              <input hidden accept="image/*, .pdf" multiple type="file" onChange={manejaArchivos} />
            </Button>
          </StyledGrid>
        </DialogContent>
        <DialogActions>
          <Button onClick={manejaCerrar} color="primary">{`${t.T_CERRAR || 'T_CERRAR'}`}</Button>
          <Box sx={{ display: (captchaValido || datosUsuario) ? 'none' : 'block' }}>
            <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY2}
              onChange={manejaCambioCaptcha}
              onExpired={manejaExpiracionCaptcha}
              ref={captchaRef}
              language="es"
            />
          </Box>
          {(captchaValido || datosUsuario) && !bloquearBoton &&
            <Button onClick={manejaReporteError} color="primary" autoFocus><MailOutline />{`${t.T_ENVIAR || 'T_ENVIAR'}`}</Button>
          }
          {bloquearBoton &&
            <Typography><CircularProgress /> {`${t.T_ENVIANDO_DATOS || 'T_ENVIANDO_DATOS'}`}</Typography>
          }
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default ReporteErrorModal