import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Box, Drawer, AppBar, Toolbar, Divider, IconButton } from '@mui/material'
import { Menu, ChevronLeft, ChevronRight } from '@mui/icons-material'

import Navbar from '../components/Navbar'
import { MenuItemList } from '../middlewares/MiddleWare'

const barraLateralMax = 240

const menuAbierto = (theme) => ({
  width: barraLateralMax,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const menuCerrado = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necesario para el contenido que está bajo la barra
  ...theme.mixins.toolbar,
}))

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  borderBottomStyle: 'solid',
  borderBottomColor: process.env.REACT_APP_COLOR || 'inherit',
  borderRadius: '0px 0 10px 10px',
  backgroundColor: theme.palette.common.white,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: barraLateralMax,
    width: `calc(100% - ${barraLateralMax}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: barraLateralMax,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...menuAbierto(theme),
      '& .MuiDrawer-paper': menuAbierto(theme),
    }),
    ...(!open && {
      ...menuCerrado(theme),
      '& .MuiDrawer-paper': menuCerrado(theme),
    }),
  }),
)

export default function Portal(props) {
  const t = global.t || {}
  const theme = useTheme()
  const [abierto, setAbierto] = React.useState(false)

  const manejaBarraAbierta = () => {
    setAbierto(true)
  }

  const manejaBarraCerrada = () => {
    setAbierto(false)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <StyledAppBar position="fixed" open={abierto}>
        <Toolbar>
          <IconButton aria-label={t.T_ABRIR_MENU_LATERAL || 'T_ABRIR_MENU_LATERAL'} onClick={manejaBarraAbierta} edge="start" sx={{ ...(abierto && { display: 'none' }), }}>
            <Menu />
          </IconButton>
          <Navbar />
        </Toolbar>
      </StyledAppBar>
      <StyledDrawer variant="permanent" open={abierto}>
        <DrawerHeader>
          <IconButton onClick={manejaBarraCerrada} size="large">
            {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <MenuItemList ruta={props.ruta}/>
      </StyledDrawer>
      <Box component="main" sx={{ flexGrow: 1, paddingTop: 8 }}>
        {props.children}
      </Box>
    </Box>
  )
}