import React from 'react'
import { styled } from '@mui/material/styles'
import jwt from 'jsonwebtoken'
import Cookies from 'universal-cookie'
import { CssBaseline } from '@mui/material' //Sirve para quitar los márgenes del body para ajustar los elementos a los bordes de la pantalla
import { Grid, Toolbar, IconButton, Typography, MenuItem, ListItemIcon, Menu, Avatar, Button, Box } from '@mui/material'
import { Accessible, MoreVert, ExitToApp, AccountBox, Settings, AccountTree, PlaylistAddCheck } from '@mui/icons-material'
import { indigo } from '@mui/material/colors'

import logo from '../images/logo_utn.svg'
import { Notification } from '../middlewares/MiddleWare'
import LogOut from '../components/LogOut'

const PREFIX = 'Navbar'
const classes = {
  navbar: `${PREFIX}-navbar`,
  logo: `${PREFIX}-logo`,
  iconoSubMenu: `${PREFIX}-iconoSubMenu`,
  iconoSubMenuOculto: `${PREFIX}-iconoSubMenuOculto`,
  menuEscritorio: `${PREFIX}-menuEscritorio`,
  menuMovil: `${PREFIX}-menuMovil`,
  titulo: `${PREFIX}-titulo`,
  iconosNavbar: `${PREFIX}-iconosNavbar`
}

const StyledMenu = styled(Menu)(({ theme }) => ({
  [`& .${classes.iconoSubMenu}`]: {
    minWidth: 30,
  },
  [`& .${classes.iconoSubMenuOculto}`]: {
    display: "none",
  }
}))

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.logo}`]: {
    marginRight: 7,
  },
  [`& .${classes.menuEscritorio}`]: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  [`& .${classes.menuMovil}`]: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  [`& .${classes.titulo}`]: {
    flexGrow: 1,
    color: indigo[900],
    fontWeight: "bolder"
  },
  [`& .${classes.iconosNavbar}`]: {
    fontSize: 30,
    color: indigo[900]
  }
}))

const cookies = new Cookies()
const rutaAlmacenamiento = process.env.REACT_APP_STORAGE
const token = cookies.get('_siguid') ? cookies.get('_siguid').split(' ')[1] : null
try {
  jwt.decode(token)
} catch (error) {
  LogOut("-1", cookies.get('_siguid').split(' ')[1])
}
const datosUsuario = jwt.decode(token)

function Navbar() {
  const t = global.t || {}
  const registrado = datosUsuario
  const [estadoMenu, setEstadoMenu] = React.useState(null)
  const [estadoMenuMovil, setEstadoMenuMovil] = React.useState(null)

  const menuAbierto = Boolean(estadoMenu)
  const menuMovilAbierto = Boolean(estadoMenuMovil)

  const manejaPerfilAperturaMenu = (event) => {
    setEstadoMenu(event.currentTarget)
  }

  const manejaMovilCierreMenu = () => {
    setEstadoMenuMovil(null)
  }

  const manejaCierreMenu = () => {
    setEstadoMenu(null)
    manejaMovilCierreMenu()
  }

  const manejaMovilAperturaMenu = (event) => {
    setEstadoMenuMovil(event.currentTarget)
  }

  const manejaCerrarSesion = () => {
    manejaCierreMenu()
    LogOut(datosUsuario.uid, token)
  }

  const manejaPerfil = () => {
    manejaCierreMenu()
    window.location.href = process.env.REACT_APP_PORTAL_PERFIL_PAGE
  }

  const manejaFlujoAprobacion = () => {
    manejaCierreMenu()
    window.location.href = process.env.REACT_APP_PORTAL_FLUJO_APROBACION_PAGE
  }

  const manejaAprobaciones = () => {
    manejaCierreMenu()
    window.location.href = process.env.REACT_APP_PORTAL_APROBACIONES_PAGE
  }

  const manejaConfiguracion = () => {
    manejaCierreMenu()
    console.log('Muestra configuración de usuario')
  }

  const menuId = 'menu-principal'
  const renderMenu = registrado && (
    <StyledMenu anchorEl={estadoMenu} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} id={menuId} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={menuAbierto} onClose={manejaCierreMenu}>
      <MenuItem divider disabled>
        <Typography variant="inherit">{datosUsuario.nombreCompleto}</Typography>
      </MenuItem>
      <MenuItem onClick={manejaPerfil}>
        <ListItemIcon className={classes.iconoSubMenu}>
          <Settings fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">{t.T_MI_PERFIL || 'T_MI_PERFIL'}</Typography>
      </MenuItem>
      <MenuItem onClick={manejaConfiguracion} className={classes.iconoSubMenuOculto}>
        <ListItemIcon className={classes.iconoSubMenu}>
          <AccountBox fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">{t.T_CONFIGURACION || 'T_CONFIGURACION'}</Typography>
      </MenuItem>
      <MenuItem onClick={manejaFlujoAprobacion} className={classes.iconoSubMenuOculto}>
        <ListItemIcon className={classes.iconoSubMenu}>
          <AccountTree fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">{t.T_MIS_FLUJOS_APROBACION || 'T_MIS_FLUJOS_APROBACION'}</Typography>
      </MenuItem>
      <MenuItem onClick={manejaAprobaciones} className={classes.iconoSubMenuOculto}>
        <ListItemIcon className={classes.iconoSubMenu}>
          <PlaylistAddCheck fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">{t.T_MIS_APROBACIONES || 'T_MIS_APROBACIONES'}</Typography>
      </MenuItem>
      <MenuItem onClick={manejaCerrarSesion}>
        <ListItemIcon className={classes.iconoSubMenu}>
          <ExitToApp fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">{t.T_CERRAR_SESION || 'T_CERRAR_SESION'}</Typography>
      </MenuItem>
    </StyledMenu>
  )

  const mobileMenuId = 'menu-principal-movil'
  const renderMobileMenu = registrado && (
    <Menu anchorEl={estadoMenuMovil} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} id={mobileMenuId} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={menuMovilAbierto} onClose={manejaMovilCierreMenu}>
      <Notification tipoMenu="móvil" />
      <MenuItem onClick={manejaPerfilAperturaMenu}>
        <IconButton aria-label={t.T_CUENTA_USUARIO || 'T_CUENTA_USUARIO'} aria-controls={mobileMenuId} aria-haspopup="true" color="inherit" size="large">
          <Avatar alt={datosUsuario.nombreCompleto} src={`${rutaAlmacenamiento}/images/avatares/${datosUsuario.uid}.png?${global.Date.now()}`}/>
        </IconButton>
        {t.T_PERFIL_USUARIO || 'T_PERFIL_USUARIO'}
      </MenuItem>
    </Menu>
  )

  return (
    <React.Fragment>
      <StyledGrid sx={{ flexGrow: 1 }}>
        <CssBaseline />
        <Toolbar position="fixed">
          <Avatar className={classes.logo} variant="rounded" alt="UTN logo" src={logo} />
          <Typography variant="h5" className={classes.titulo}>{t.T_SIGLAS_SISTEMA || 'T_SIGLAS_SISTEMA'}</Typography>
          <IconButton aria-label={t.T_ABRIR_OPCIONES_ACCESIBILIDAD || 'T_ABRIR_OPCIONES_ACCESIBILIDAD'} color="inherit" href="/Accesibility" size="large">
            <Accessible className={classes.iconosNavbar} />
          </IconButton>
          {registrado && (
            <Box>
              <Box className={classes.menuEscritorio}>
                <Notification tipoMenu="escritorio" className={classes.iconosNavbar} />
                <IconButton edge="end" aria-label={t.T_CUENTA_USUARIO || 'T_CUENTA_USUARIO'} aria-controls={menuId} aria-haspopup="true" onClick={manejaPerfilAperturaMenu} color="inherit" size="large">
                  <Avatar alt={datosUsuario.nombreCompleto} src={`${rutaAlmacenamiento}/images/avatares/${datosUsuario.uid}.png?${global.Date.now()}`}/>
                </IconButton>
              </Box>
              <Box className={classes.menuMovil}>
                <IconButton aria-label={t.T_MOSTRAR_MAS || 'T_MOSTRAR_MAS'} aria-controls={mobileMenuId} aria-haspopup="true" onClick={manejaMovilAperturaMenu} color='inherit' size="large">
                  <MoreVert className={classes.iconosNavbar}/>
                </IconButton>
              </Box>
            </Box>
          )}
          {!registrado && (
            <Box>
              <Button href={process.env.REACT_APP_PORTAL_PERFIL_REG_PAGE}>{t.T_CREAR_CUENTA || 'T_CREAR_CUENTA'}</Button>
              <Button href={process.env.REACT_APP_LOGIN_PAGE}>{t.T_INICIAR_SESION || 'T_INICIAR_SESION'}</Button>
            </Box>
          )}
        </Toolbar>
        {renderMobileMenu}
        {renderMenu}
      </StyledGrid>
    </React.Fragment>
  )
}

export default Navbar