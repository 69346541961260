import React from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Grid } from '@mui/material'
import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import AccesibilityHC from '../components/Accesibility'
import { PageLoading } from '../components/PageLoading'

const PREFIX = 'Accesibility'
const classes = {
  menu: `${PREFIX}-menu`
}

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.menu}`]: {
    background: '#B0B5C1',
    width: '100%',
    height: 'auto',
    padding: 8,
  }
}))

class Accesibility extends React.Component {
  state = {
    cargando: false,
    error: null,
  }

  handleSubmit = async e => {
    e.preventDefault()
    this.setState({ cargando: false })
    window.location.reload()
  }

  render() {
    const t = global.t || {}

    if (this.state.cargando) {
      return <PageLoading />
    }

    return (
      <React.Fragment>
        <StyledGrid>
          <BottomNavigation className={classes.menu} showLabels><BottomNavigationAction label={t.T_MENU || 'T_MENU'} /></BottomNavigation>

          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Typography variant='h3'>{t.T_CONFIGURACION_ACCESIBILIDAD || 'T_CONFIGURACION_ACCESIBILIDAD'}</Typography>
            <AccesibilityHC onSubmit={this.handleSubmit}  error={this.state.error} />
          </Grid>
        </StyledGrid>
      </React.Fragment>
    )
  }
}

export default (Accesibility)
