export default async function Textos(secciones, idioma) {
  const miscUrl = process.env.REACT_APP_MISC_API || 'http://localhost:3002' 
  const response = await fetch(`${miscUrl}/texto/${secciones||'-'}&${idioma||'-'}`, { 
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
  })
  .catch(err => {
    throw (err)
  })
  const datos = await response.json()
  return datos.body || {}
}