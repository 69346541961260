import React from 'react'
import { styled } from '@mui/material/styles'
import { Grid, CardMedia, NoSsr } from "@mui/material"

import PageError from '../components/PageError'

const PREFIX = 'ErpFrame'
const classes = {
  iframe: `${PREFIX}-iframe`
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.iframe}`]: {
    minHeight: `calc(100vh - ${theme.spacing(14)})`,
    border: 0,
  }
}))

const tipoProtocolo = process.env.REACT_APP_SEGURO === 'production' ? 'https' : 'http'

class ErpFrame extends React.Component {
  render() {
    const t = global.t || {}

    try {
      return (
        <React.Fragment>
          <NoSsr>
            <StyledGrid>
              <CardMedia id="contenido" className={classes.iframe} component="iframe" image={`${tipoProtocolo}://${atob(this.props.match.params.enlace)}`} allow="geolocation" />
            </StyledGrid>
          </NoSsr>
        </React.Fragment>
      )
    } catch(error) {
      return <PageError error={{message: (t.T_RECURSO_NO_ENCONTRADO || 'T_RECURSO_NO_ENCONTRADO')}} />
    }
  }
}

export default (ErpFrame)
