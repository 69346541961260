import React, { useEffect, useState, Suspense, lazy } from 'react'
import ReactGA from 'react-ga4'
import { Router, Switch, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Cookies from 'universal-cookie'

import Textos from "../middlewares/Textos"
import Layout from './Layout'
import Home from '../pages/Home'
import Erp from '../pages/Erp'
import Accesibility from '../pages/Accesibility'
import ErpFrame from '../pages/ErpFrame'
import Notification from '../pages/Notications'
import LogOut from '../components/LogOut'
import NotFound from '../pages/NotFound'

const cookies = new Cookies()
const HightContrast = lazy(() => import('../components/accesibility/HighContrast'))
const InvertColors = lazy(() => import('../components/accesibility/InvertColors'))
const MaxText = lazy(() => import('../components/accesibility/MaxText'))

const history = createBrowserHistory()
ReactGA.initialize(process.env.REACT_APP_TRACKING_ID || 'UA-000000-01')
global.MiscUrl = process.env.REACT_APP_MISC_API || 'http://localhost:3002'

history.listen((location, action) => {
  ReactGA.send({ hitType:'pageview', page:encodeURI(`${location.pathname}${location.search}`)})
})

function App() {
  const [textos, cargaTexto] = useState(false)

  useEffect(() => {
    async function obtieneTextos() {
      try {
        global.t = await Textos('general,framework,perfil', cookies.get('idioma') || 'es')
      } catch (error) {
        console.error("Error obteniendo los textos sustitutivos", error)
      }
      cargaTexto(true)
    }
    obtieneTextos()
  }, [])

  if (!textos) {
    return <>Cargando...</>
  }

  return (
    <Router history={history}>
      <Layout ruta={history}>
        <Suspense fallback={<div>Cargando...</div>}>
          {(cookies.get('AltoContraste')) && <HightContrast />}
          {(cookies.get('InvertirColores')) && <InvertColors />}
          {(cookies.get('AumentoLetra')) && <MaxText />}
        </Suspense>
        <Switch>
          <Route exact path="/" component={props => <Home {...props} />} />
          <Route exact path="/modulos" component={props => <Erp {...props} />} />
          <Route exact path="/modulos/:moduloId" component={props => <Erp {...props} />} />
          <Route exact path="/publico/:moduloId" component={props => <Erp {...props} />} />
          <Route exact path="/accesibility" component={props => <Accesibility {...props} />} />
          <Route exact path="/enlace/:moduloId/:enlace" component={props => <ErpFrame {...props} />} />
          <Route exact path="/notifications/:nid" component={props => <Notification {...props} />} />
          <Route exact path="/signout" component={LogOut} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </Router>
  )
}

export default App