import Cookies from 'universal-cookie'

const cookies = new Cookies()

const delay = ms => new Promise(resolve => setTimeout(resolve, ms))
const randomNumber = (min = 0, max = 1) =>
  Math.floor(Math.sin(1) * (max - min + 1)) + min
const simulateNetworkLatency = (min = 30, max = 1500) =>
  delay(randomNumber(min, max))

async function cargaDatos(moduloNivel) {
  const t = global.t || {}
  var mensajeError = ''
  var datos = []
  const token = cookies.get('_siguid') ? cookies.get('_siguid') : null

  //Lo siguiente hace una simulación de latencia para las pruebas locales.
  if (process.env.REACT_APP_DOMINIO === 'localhost') {
    await simulateNetworkLatency()
  }
  switch (moduloNivel.split("/")[0]) {
    case 'info':
      try {
        const response = await fetch(`${process.env.REACT_APP_PORTAL_API}/${moduloNivel}/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          }
        })
        const modulos = await response.json()
        if (response.status === 200 && modulos.body) {
          datos[0] = modulos.body
          return datos
        }
        else {
          mensajeError = modulos.error
          throw Error(mensajeError)
        }
      }
      catch (error) {
        throw Error(mensajeError || (t.T_ERROR_500 || 'T_ERROR_500'))
      }
    case 'public':
      if (!moduloNivel.split('lic/')[1]) {
        moduloNivel += '/0'
      }
      try {
        const response = await fetch(`${process.env.REACT_APP_PORTAL_API}/${moduloNivel}/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          }
        })
        const modulos = await response.json()
        if (response.status === 200 && modulos.body) {
          datos[0] = modulos.body
          datos[1] = []
          return datos
        }
        else {
          mensajeError = modulos.error
          throw Error(mensajeError)
        }
      }
      catch (error) {
        throw Error(mensajeError || (t.T_ERROR_500 || 'T_ERROR_500'))
      }
    case 'modules':
      if (!moduloNivel.split('les/')[1]) {
        moduloNivel += '/0'
      }
      try {
        const response = await fetch(`${process.env.REACT_APP_PORTAL_API}/${moduloNivel}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            authorization: token,
          }
        })
        const modulos = await response.json()
        if (response.status === 200 && modulos.body) {
          try {
            const response = await fetch(`${process.env.REACT_APP_PORTAL_API}/favorite`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                authorization: token,
              }
            })
            const favoritos = await response.json()
            if (response.status === 200) {
              datos[0] = modulos.body
              datos[1] = favoritos.body
              return datos
            }
            else {
              mensajeError = favoritos.error
              throw Error(mensajeError)
            }
          }
          catch (error) {
            throw Error(mensajeError || (t.T_ERROR_500 || 'T_ERROR_500'))
          }
        }
        else {
          mensajeError = modulos.error
          throw Error(mensajeError)
        }
      }
      catch (error) {
        throw Error(mensajeError || (t.T_ERROR_500 || 'T_ERROR_500'))
      }
    default:
      throw Error(t.T_RECURSO_NO_ENCONTRADO || 'T_RECURSO_NO_ENCONTRADO')
  }
}

const api = {
  modulos: {
    listadoPublico(moduloNivel = 0) {
      return cargaDatos(`public/${moduloNivel}`)
    },
    listadoModulos(moduloNivel = 0) {
      return cargaDatos(`modules/${moduloNivel}`)
    },
    moduloInfo(moduloNivel) {
      return cargaDatos(`info/${moduloNivel}`)
    },
  },
}

export default api