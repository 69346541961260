import React from 'react'
import { styled } from '@mui/material/styles'
import { CircularProgress, Grid, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { Skeleton } from '@mui/material'

const PREFIX = 'PageLoading'
const classes = {
  altoMensaje: `${PREFIX}-altoMensaje`,
  espaciadoSuperior: `${PREFIX}-espaciadoSuperior`,
  espaciado: `${PREFIX}-espaciado`,
  esqueletoMenuSuperior: `${PREFIX}-esqueletoMenuSuperior`,
  esqueletoBuscar: `${PREFIX}-esqueletoBuscar`,
  esqueletoFavoritos: `${PREFIX}-esqueletoFavoritos`,
  esqueletoModulos: `${PREFIX}-esqueletoModulos`,
  esqueletoMenuItems: `${PREFIX}-esqueletoMenuItems`
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.altoMensaje}`]: {
    height: '80vh'
  },
  [`& .${classes.espaciadoSuperior}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${classes.espaciado}`]: {
    padding: theme.spacing(2),
  },
  [`& .${classes.esqueletoMenuSuperior}`]: {
    width: '100%',
    height: 28,
    transform: 'inherit',
  },
  [`& .${classes.esqueletoBuscar}`]: {
    width: 800,
    height: 56,
    transform: 'inherit',
  },
  [`& .${classes.esqueletoFavoritos}`]: {
    height: 56,
    transform: 'inherit',
  },
  [`& .${classes.esqueletoModulos}`]: {
    width: 250,
    height: 222,
    margin: 8,
    transform: 'inherit',
  },
  [`& .${classes.esqueletoMenuItems}`]: {
    width: 40,
    height: 40,
    margin: 4,
    transform: 'inherit',
  },
}))

function PageLoading() {
  return (
    <StyledGrid container direction="row" justifyContent="center" alignItems="center" className={classes.altoMensaje} >
      <CircularProgress />
    </StyledGrid>
  )
}

function SkeletonLoading(props) {
  const tamaniosAcordion = { xs: "100%", sm: "100%", lg: "1100px", xl: "1400px" }
  switch (props.origen) {
    case 'modulos':
      return (
        <React.Fragment>
          <StyledGrid>
            <Skeleton className={classes.esqueletoMenuSuperior} />
            <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.espaciadoSuperior}>
              <Box width={1} justifyContent="center" display="flex">
                <Skeleton animation="wave" className={classes.esqueletoBuscar} />
              </Box>
              <Box width={tamaniosAcordion} className={classes.espaciadoSuperior}>
                <Skeleton animation="wave" className={classes.esqueletoFavoritos} />
              </Box>
              <Box width={tamaniosAcordion} className={classes.espaciadoSuperior}>
                <Accordion expanded>
                  <AccordionSummary>
                    <Skeleton animation="wave" width="130px" />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                      {Array.from(new Array(5)).map((item, index) => (
                        <Box key={index}>
                          <Skeleton className={classes.esqueletoModulos} />
                        </Box>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
          </StyledGrid>
        </React.Fragment>
      )
    case 'publico':
      return (
        <React.Fragment>
          <StyledGrid>
            <Skeleton className={classes.esqueletoMenuSuperior} />
            <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.espaciadoSuperior}>
              <Box width={1} justifyContent="center" display="flex">
                <Skeleton animation="wave" className={classes.esqueletoBuscar} />
              </Box>
              <Box width={tamaniosAcordion} className={classes.espaciadoSuperior}>
                <Accordion expanded>
                  <AccordionSummary>
                    <Skeleton animation="wave" width="130px" />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                      {Array.from(new Array(5)).map((item, index) => (
                        <Box key={index}>
                          <Skeleton className={classes.esqueletoModulos} />
                        </Box>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
          </StyledGrid>
        </React.Fragment>
      )
    case 'menuLateral':
      return (
        <React.Fragment>
          <StyledGrid>
            <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.espaciadoSuperior}>
              <Box>
                {Array.from(new Array(7)).map((item, index) => (
                  <Box key={index} width='100%'>
                    <Skeleton className={classes.esqueletoMenuItems} />
                  </Box>
                ))}
              </Box>
            </Grid>
          </StyledGrid>
        </React.Fragment>
      )
    default:
      return (
        <React.Fragment>
          <StyledGrid>
            <Grid container direction="row">
              <Grid container justifyContent="center" alignItems="flex-start" item xs={12} sm={12} md={12} lg={7} className={classes.espaciado}>
                <Box width={tamaniosAcordion} className={classes.espaciadoSuperior}>
                  <Accordion expanded>
                    <AccordionSummary>
                      <Skeleton animation="wave" width="130px" />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                        {Array.from(new Array(3)).map((item, index) => (
                          <Box key={index}>
                            <Skeleton className={classes.esqueletoModulos} />
                          </Box>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Grid>
              <Grid container direction="column" justifyContent="center" alignItems="flex-start" item xs={12} sm={12} md={12} lg={5} className={classes.espaciado}>
                <Skeleton animation="wave" className={classes.esqueletoFavoritos} width='100%' />
                {Array.from(new Array(4)).map((item, index) => (
                  <Box key={index} width='100%' className={classes.espaciadoSuperior}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" width='40%' />
                  </Box>
                ))}
              </Grid>
            </Grid>
          </StyledGrid>
        </React.Fragment>
      )
  }
}

export {
  PageLoading,
  SkeletonLoading
}


