import React from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Grid } from '@mui/material'

const PREFIX = 'PageError'
const classes = {
  altoMensaje: `${PREFIX}-altoMensaje`
}

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.altoMensaje}`]: {
    height: '80vh'
  }
}))

function PageError(props) {
  return (
    <StyledGrid container direction="row" justifyContent="center" alignItems="center" className={classes.altoMensaje} >
      <Typography variant="h4">{props.error.message}</Typography>
    </StyledGrid>
  )
}

export default PageError