import React, { Component } from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Grid, Box } from '@mui/material'
import Parser from 'html-react-parser'

import ErpList from '../components/ErpList'
import MiniLoader from '../components/MiniLoader'
import { SkeletonLoading } from '../components/PageLoading'
import PageError from '../components/PageError'
import api from '../api'

const PREFIX = 'Home'
const classes = {
  espaciadoSuperior: `${PREFIX}-espaciadoSuperior`
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.espaciadoSuperior}`]: {
    padding: theme.spacing(2),
  }
}))

class Home extends Component {
  state = {
    cargando: true,
    error: null,
    datos: undefined,
  }

  componentDidMount() {
    this.fetchData()

    this.intervalId = setInterval(this.fetchData, process.env.REACT_APP_RECARGA_MODULOS || 30000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  fetchData = async () => {
    this.setState({ cargando: true, error: null })

    try {
      const data = await api.modulos.listadoPublico()
      this.setState({ cargando: false, datos: data })
    } catch (error) {
      this.setState({ cargando: false, error: error })
    }
  }

  render() {
    const t = global.t || {}

    if (this.state.cargando === true && !this.state.datos) {
      return <SkeletonLoading />
    }

    if (this.state.error) {
      clearInterval(this.intervalId)
      return <PageError error={this.state.error} />
    }

    return (
      <StyledGrid container direction="row">
        <Grid justifyContent="center" alignItems="flex-start" item xs={12} sm={12} md={12} lg={7} className={classes.espaciadoSuperior}>
          <ErpList modulos={this.state.datos} idModulo='Home' />
          <Box width="100px" className={classes.espaciadoSuperior}>{this.state.cargando && <MiniLoader />}</Box>
        </Grid>
        <Grid container justifyContent="center" alignItems="flex-start" item xs={12} sm={12} md={12} lg={5} className={classes.espaciadoSuperior}>
          <Typography variant="h3">{t.T_INFORMACION_GENERAL || 'T_INFORMACION_GENERAL'}</Typography>
          <Typography variant="span">{Parser(t.T_MENSAJE_SISTEMA_PANTALLA_INICIO || 'T_MENSAJE_SISTEMA_PANTALLA_INICIO')}</Typography>
        </Grid>
      </StyledGrid>
    )
  }
}

export default (Home)